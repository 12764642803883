
import React, { useState, useEffect } from 'react';
import { useClerk, useOrganization } from "@clerk/clerk-react";
import axios from 'axios';
import SubscriptionSidebar from './SubscriptionSidebar';
import './ManageSubscriptionPage.css';
  
export default function ManageSubscription() {
  const [currentTier, setCurrentTier] = useState('FREE');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [isLoading, setIsLoading] = useState(true);
  const { organization } = useOrganization();
  const { session, getToken } = useClerk();

  // Keep the payment links but add price IDs
  const STRIPE_PLANS = {
    GROWTH: {
      monthly: {
        link: 'https://buy.stripe.com/test_28o4gvaS75XX2LSaEH',
        priceId: 'price_1QKfX7IJU39QO7T6J8QedcDM' 
      },
      yearly: {
        link: 'https://buy.stripe.com/test_7sI9AP5xNcmleuAeUW',
        priceId: 'price_1QV6qfIJU39QO7T6JCsZU5cP' 
      }
    },
    SCALE: {
      monthly: {
        link: 'https://buy.stripe.com/test_28o4gvaS75XX2LSaEH',
        priceId: 'price_1QQ4jLIJU39QO7T6SBe9WAp0' 
      },
      yearly: {
        link: 'https://buy.stripe.com/test_7sI9AP5xNcmleuAeUW',
        priceId: 'price_1QV6qeIJU39QO7T6uiVNv9zX' 
      }
    }
  };

  const plans = [
    {
      title: 'Free',
      subtitle: 'For Individual Users',
      price: 0,
      features: [
        '5 Leads/month',
        '500 Emails/month',
        '30 AI Message Analysis',
        'Unlimited Team Members',
        '1 Client',
        '3 Platforms'
      ],
      storage: '1GB',
      emails: '20K/Mo'
    },
    {
      title: 'Growth',
      subtitle: 'For Small Agencies',
      price: billingCycle === 'monthly' ? 199 : 1990,
      features: [
        '200 Leads/Month',
        '15 Email Campaigns',
        '400 AI Messages',
        'Unlimited Team Members',
        '10 Client Sites',
        '3 Platforms'
      ],
      storage: '1GB',
      emails: '20K/Mo',
      stripePlans: STRIPE_PLANS.GROWTH
    },
    {
      title: 'Scale',
      subtitle: 'For Mid-Size Agencies',
      price: billingCycle === 'monthly' ? 499 : 4990,
      features: [
        '1000 Leads/Month',
        '40 Email Campaigns',
        '800 AI Messages',
        'Unlimited Team Members',
        '25 Client Sites',
        '6 Platforms'
      ],
      storage: '2GB',
      emails: '60K/Mo',
      stripePlans: STRIPE_PLANS.SCALE
    }
  ];

  const PricingBox = ({ 
    title, 
    subtitle, 
    price, 
    features, 
    storage, 
    emails, 
    stripePlans,
    currentTier,
    isScale 
  }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const isCurrentPlan = currentTier === title.toUpperCase();

    const handleUpgrade = async () => {
      if (isCurrentPlan || !stripePlans) return;
      
      setIsProcessing(true);
      try {
        // Use session.getToken() instead of getToken()
        const token = await session.getToken();
        
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/prepare-customer`,
          {
            organizationId: organization.id
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-Organization-ID': organization.id
            }
          }
        );

        // After successful customer preparation, use the priceId instead of link
        const plan = stripePlans[billingCycle];
        const checkoutResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`,
          {
            priceId: plan.priceId,
            organizationId: organization.id
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-Organization-ID': organization.id
            }
          }
        );

        // Redirect to Stripe Checkout
        if (checkoutResponse.data.url) {
          window.location.href = checkoutResponse.data.url;
        }
      } catch (error) {
        console.error('Error preparing customer:', error);
        setIsProcessing(false);
      }
    };

    return (
      <div className={`pricing-card ${isCurrentPlan ? 'current' : ''} ${isScale ? 'scale' : ''}`}>
        {isCurrentPlan && (
          <span className="badge current">Current Plan</span>
        )}
        {isScale && !isCurrentPlan && (
          <span className="badge popular">Popular</span>
        )}

        <div className="plan-header">
          <h3 className="plan-title">{title}</h3>
          <p className="plan-subtitle">{subtitle}</p>
        </div>

        <div className="price-section">
          <div className="price-amount">
            <span className="currency">$</span>
            <span className="amount">{price}</span>
          </div>
          <div className="period">
            {price === 0 ? 'Forever Free' : billingCycle === 'monthly' ? 'Per Month' : 'Per Year'}
          </div>
        </div>

        <ul className="features-list">
          {features.map((feature, index) => (
            <li key={index} className="feature-item">
              <span className="checkmark">✓</span>
              <span className="feature-text">{feature}</span>
            </li>
          ))}
        </ul>

        <div className="storage-info">
          <span>Storage: {storage}</span>
          <span>Emails: {emails}</span>
        </div>

        <button 
          className={`action-button ${isCurrentPlan ? 'current' : ''}`}
          onClick={handleUpgrade}
          disabled={isCurrentPlan || isProcessing}
        >
          {isProcessing ? 'Processing...' : isCurrentPlan ? 'Current Plan' : 'Upgrade Now'}
        </button>
      </div>
    );
  };

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (organization?.id && session) {
        try {
          const token = await session.getToken();
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'X-Organization-ID': organization.id
              }
            }
          );
          console.log('Subscription status:', response.data);
          setCurrentTier(response.data.tier);
          setIsLoading(false);
        } catch (error) {
          console.error('Error checking subscription:', error);
          setIsLoading(false);
        }
      }
    };

    checkSubscriptionStatus();
  }, [organization?.id, session]);

  if (isLoading) {
    return (
      <div className="page-container">
        <SubscriptionSidebar />
        <main className="main-content">
          <div className="loading">Loading subscription details...</div>
        </main>
      </div>
    );
  }

  return (
    <div className="page-container">
      <SubscriptionSidebar />
      
      <main className="main-content">
        <h1 className="page-title">Subscription Plans</h1>

        <div className="toggle-section">
          <div className="billing-toggle">
            <button 
              className={`toggle-button ${billingCycle === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
            <button 
              className={`toggle-button ${billingCycle === 'yearly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('yearly')}
            >
              Yearly (Save 20%)
            </button>
          </div>
        </div>

        <div className="pricing-cards">
          {plans.map((plan, index) => (
            <PricingBox
              key={index}
              {...plan}
              currentTier={currentTier}
              isScale={plan.title === 'Scale'}
              billingCycle={billingCycle}
            />
          ))}
        </div>

        <div className="features-bar">
          {['SSL Security', 'Daily Backups', '99.9% Uptime', 'Training Resources'].map((feature) => (
            <div key={feature} className="feature-item">
              <span className="checkmark">✓</span>
              <span>{feature}</span>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}