import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useUser, useOrganization as useClerkOrganization } from "@clerk/clerk-react";

const PrivateRoute = ({ children }) => {
  const { isLoaded: userLoaded, isSignedIn } = useUser();
  const { organization, isLoaded: orgLoaded } = useClerkOrganization();

  if (!userLoaded || !orgLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }

  // Add organization requirement for specific routes
  const requiresOrg = window.location.pathname.match(/\/(analytics|datasource-connector)/);
  if (requiresOrg && !organization) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Organization Required
        </Typography>
        <Typography>
          Please select or create an organization to access this feature.
        </Typography>
      </Box>
    );
  }

  return children;
};

export default PrivateRoute;