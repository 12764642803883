import React from 'react';
import { ClerkProvider, useUser } from '@clerk/clerk-react';
import LeadImport from './components/MainPage/LeadImport';
import CampaignsTable from './components/CampaignPage/CampaignsTable';
import LeadManagement from './components/leadManagementPage/LeadManagement';
import CampaignAnalytics from './components/CampaignAnalyticsPAge/CampaignAnalytics';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DataSourceConnector from './components/DataSourceConnector';
import { Login } from './components/Login';
import { Register } from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import ManageSubscriptionPage from './components/ManageSubscriptionPage';
import AnalyticsPage from './components/AnalyticsPage';
import HomePage from './components/HomePage';
import OAuthComplete from './components/OAuthComplete';
import SettingsPage from './components/SettingsPage';
import { OrganizationProvider } from './components/OrganizationProvider';
import SubscriptionSuccess from './components/SubscriptionSuccess';

const theme = createTheme();
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function AuthenticatedRoutes() {
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={isSignedIn ? <Navigate to="/home" replace /> : <Login />} />
      <Route path="/login/factor-one" element={<Login />} />
      <Route path="/register/*" element={<Register />} />
      <Route path="/verify-email-address" element={<Register />} />
      <Route path="/" element={isSignedIn ? <Navigate to="/home" replace /> : <Navigate to="/login" replace />} />

      // In your router setup
      <Route path="/oauth-complete" element={<OAuthComplete />} />
      <Route path="/oauth-error" element={<OAuthComplete />} />
      <Route 
        path="/home" 
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        } 
      />

      <Route path="/manage-subscription/*" element={<ManageSubscriptionPage />} />

      <Route path="/settings/*" element={<SettingsPage />} />
      <Route 
        path="/analytics" 
        element={
          <PrivateRoute>
            <AnalyticsPage />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/datasource-connector" 
        element={
          <PrivateRoute>
            <DataSourceConnector />
          </PrivateRoute>
        } 
      />
      <Route
        path="/main"
        element={
          <PrivateRoute>
            <LeadImport />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns"
        element={
          <PrivateRoute>
            <CampaignsTable />
          </PrivateRoute>
        }
      />
      <Route
        path="/leads"
        element={
          <PrivateRoute>
            <LeadManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaign-analytics"
        element={
          <PrivateRoute>
            <CampaignAnalytics />
          </PrivateRoute>
        }
      />
      <Route path="/subscription-success" element={<SubscriptionSuccess />} />
      <Route path="/" element={isSignedIn ? <Navigate to="/home" replace /> : <Navigate to="/login" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <OrganizationProvider>
          <Router>
            <React.Suspense fallback={<div>Loading...</div>}>
              <AuthenticatedRoutes />
            </React.Suspense>
          </Router>
        </OrganizationProvider>
      </ThemeProvider>
    </ClerkProvider>
  );
}
export default App;