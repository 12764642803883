import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useSubscriptionData = (isSignedIn, organization, getToken) => {
  const [subscriptionData, setSubscriptionData] = useState({
    tier: {
      name: 'FREE',
      max_messages: 10,
      max_monthly_input_tokens: 120000,
      max_monthly_output_tokens: 60000
    },
    usage: {
      messages: { used: 0, limit: 10 },
      input_tokens: { used: 0, limit: 120000 },
      output_tokens: { used: 0, limit: 60000 },
      reset_date: null
    },
    isLoading: true,
    error: null
  });

  const fetchData = useCallback(async () => {
    if (!isSignedIn || !organization?.id) {
      setSubscriptionData(prev => ({ ...prev, isLoading: false }));
      return;
    }

    try {
      const token = await getToken();
      const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      
      const response = await axios.get(`${BACKEND_URL}/api/usage`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-ID': organization.id,
          'Content-Type': 'application/json'
        }
      });

      setSubscriptionData({
        tier: {
          name: response.data.tier.name,
          max_messages: response.data.tier.max_messages,
          max_monthly_input_tokens: response.data.tier.max_monthly_input_tokens,
          max_monthly_output_tokens: response.data.tier.max_monthly_output_tokens
        },
        usage: {
          messages: {
            used: parseInt(response.data.usage.messages.used) || 0,
            limit: parseInt(response.data.usage.messages.limit) || 10
          },
          input_tokens: {
            used: parseInt(response.data.usage.input_tokens.used) || 0,
            limit: parseInt(response.data.usage.input_tokens.limit) || 120000
          },
          output_tokens: {
            used: parseInt(response.data.usage.output_tokens.used) || 0,
            limit: parseInt(response.data.usage.output_tokens.limit) || 60000
          },
          reset_date: response.data.usage.reset_date
        },
        isLoading: false,
        error: null
      });
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      setSubscriptionData(prev => ({
        ...prev,
        isLoading: false,
        error: error.message
      }));
    }
  }, [isSignedIn, organization?.id, getToken]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, [fetchData]);

  const refreshData = () => fetchData();

  return {
    ...subscriptionData,
    refreshData
  };
};