import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, TextField, Select, MenuItem, Paper, List, ListItem, LinearProgress,ListItemIcon, ListItemText, Checkbox, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HypeLogo from '../HA_Logo.png';
import GA4Logo from '../GA4_Logo.png';
import GSCLogo from '../gsclogo.webp';
import GoogleIcon from '../Google_Icon.webp';
import WebContentLogo from '../WebContent_Logo.jpg'; 
import { useUser, useAuth, useOrganization } from "@clerk/clerk-react";
import { OrganizationSwitcher } from "@clerk/clerk-react";
import FacebookIcon from '../Facebook_Icon.png';
import WooCommerceIcon from '../woocommerce-logo.png';

import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://ha-dataconnector-backend-vkuu.onrender.com';
console.log('Using backend URL:', BACKEND_URL);


const useAuthenticatedApi = () => {
  const { getToken } = useAuth();
  const { isSignedIn, isLoaded } = useUser();

  const callApi = async (method, url, data = null) => {
    try {
      console.log(`Making ${method} request to ${url}`);
      const token = await getToken({ skipCache: true }); // Always get fresh token
      console.log('Token obtained:', token.substring(0, 10) + '...');

      const api = axios.create({
        baseURL: BACKEND_URL,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      // Add response interceptor for token refresh
      api.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          
          if (error.response?.status === 401 && !originalRequest._retry && isSignedIn) {
            originalRequest._retry = true;
            try {
              const newToken = await getToken({ skipCache: true });
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              return axios(originalRequest);
            } catch (refreshError) {
              console.error('Failed to refresh token:', refreshError);
              throw refreshError;
            }
          }
          throw error;
        }
      );

      const response = await api({
        method,
        url,
        data
      });

      console.log(`Response received from ${url}:`, response.status, response.statusText);
      return response.data;
    } catch (error) {
      console.error(`Error in API call to ${url}:`, error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      throw error;
    }
  };

  // Keep session alive
  useEffect(() => {
    if (!isSignedIn) return;

    const keepAliveCheck = async () => {
      try {
        await callApi('get', '/api/debug_token');
      } catch (error) {
        console.error('Session check failed:', error);
      }
    };

    const interval = setInterval(keepAliveCheck, 4 * 60 * 1000);
    return () => clearInterval(interval);
  }, [isSignedIn]);

  return {
    callApi,
    isAuthenticated: isSignedIn,
    isReady: isLoaded
  };
};


const api = axios.create({
  baseURL: BACKEND_URL
});

const AppContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  fontFamily: '"Nunito Sans", sans-serif',
  backgroundColor: '#fff',
});

const Sidebar = styled(Box)({
  width: 250,
  borderRight: '1px solid #E2E8F0',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
});

const MainContent = styled(Box)({
  flexGrow: 1,
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
});

const StyledButton = styled(Button)({
  borderRadius: 8,
  textTransform: 'none',
  padding: '8px 16px',
  fontSize: '12px',
  fontWeight: 600,
});

const StyledSelect = styled(Select)({
  backgroundColor: '#F1F5F9',
  borderRadius: 8,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});


const StyledTableCell = styled(TableCell)({
  fontSize: '0.75rem', // Reduced font size
  padding: '4px 8px', // Adjusted padding
});

const PlatformListItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: 8,
  marginBottom: 4,
  padding: '8px 12px',
  backgroundColor: selected ? '#F1F5F9' : 'transparent',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
}));

const ContentCard = styled(Paper)({
  padding: '24px',
  backgroundColor: '#fff',
  borderRadius: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  width: '100%',
  maxWidth: '800px', // Increased from 480px to allow for wider table
  margin: '20px auto',
});



const DataSourceConnector = () => {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();
  const { organization, isLoaded: orgLoaded } = useOrganization();
  const { callApi, isAuthenticated, isReady } = useAuthenticatedApi();
  const { getToken } = useAuth();
  const [platforms, setPlatforms] = useState([
    {"name": "Google Analytics", "icon": GA4Logo, "id": "ga4"},
    {"name": "GA4 Platform Data", "icon": GA4Logo, "id": "ga4_platform"},
    {"name": "GA4 User Data", "icon": GA4Logo, "id": "ga4_user"},
    {"name": "Google Search Console", "icon": GSCLogo, "id": "gsc"},
    {"name": "Web Content", "icon": WebContentLogo, "id": "webcontent"},
    {"name": "Facebook Ads", "icon": FacebookIcon, "id": "facebook_ads"} ,
    {"name": "WooCommerce", "icon": WooCommerceIcon, "id": "woocommerce"}

  ]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const [userDatabases, setUserDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlyConnected, setShowOnlyConnected] = useState(false);
  const [step, setStep] = useState(1);
  const [dateRange, setDateRange] = useState(30);
  const [newClientName, setNewClientName] = useState('');
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);  
  const [analyticsHtml, setAnalyticsHtml] = useState('');
  const [selectedSources, setSelectedSources] = useState([]);
  const [storageStatus, setStorageStatus] = useState({});
  const [dataSourceQueue, setDataSourceQueue] = useState([]);
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [webContentUrl, setWebContentUrl] = useState('');
  const [propertySearchTerm, setPropertySearchTerm] = useState('');

  const filteredProperties = properties.filter(property => {
    const searchTerm = propertySearchTerm.toLowerCase();
    const propertyName = (property.name || property.displayName || '').toLowerCase();
    const propertyId = (property.id || '').toLowerCase();
    return propertyName.includes(searchTerm) || propertyId.includes(searchTerm);
  });

  const createAuthPopup = (url, platform) => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    
    return window.open(
      url,
      `${platform} Sign-In`,
      `width=${width},height=${height},left=${left},top=${top},toolbar=0,location=0,status=0,menubar=0`
    );
  };
  

  const [webContentStatus, setWebContentStatus] = useState({
    loading: false,
    progress: 0,
    error: null,
    success: false
  });
  
  const handleHomeClick = () => {
    navigate('/analytics');
  };
  
  const fetchProperties = useCallback(async () => {
    if (!selectedPlatform) return;
  
    try {
      setLoading(true);
      setError(null);
      const token = await getToken();
  
      // Skip API call for web content platform
      if (selectedPlatform.id === 'webcontent') {
        setProperties([]);
        setLoading(false);
        return;
      }
  
      let response;
      
      if (['ga4', 'ga4_platform', 'ga4_user'].includes(selectedPlatform.id)) {
        response = await axios.get(`${BACKEND_URL}/api/ga4_properties`, {
          params: { ga4_group: selectedPlatform.id },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
      } else if (selectedPlatform.id === 'gsc') {
        response = await axios.get(`${BACKEND_URL}/api/gsc_properties`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
      } else if (selectedPlatform.id === 'facebook_ads') {
        response = await axios.get(`${BACKEND_URL}/api/facebook_ads_properties`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
      }
      
      if (response && response.data.properties) {
        setProperties(response.data.properties);
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Authentication failed. Please reconnect your account.');
        } else if (error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else {
          setError('An error occurred while fetching properties. Please try again later.');
        }
      } else {
        setError('Network error. Please check your internet connection and try again.');
      }
    } finally {
      setLoading(false);
    }
  }, [selectedPlatform, getToken]);
  
  const fetchUserDatabases = useCallback(async (retryCount = 0) => {
    try {
      setLoading(true);
      const token = await getToken();
      if (!token && retryCount < 3) {
        setTimeout(() => fetchUserDatabases(retryCount + 1), 1000);
        return;
      }
      if (!token) {
        throw new Error("Failed to get authentication token");
      }
      const response = await axios.get(`${BACKEND_URL}/api/clients`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      // Store the raw array directly
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching user databases:', error);
      setError(error.message || 'An error occurred while fetching user databases');
    } finally {
      setLoading(false);
    }
  }, [getToken]);


  useEffect(() => {
    if (isLoaded && isSignedIn) {
      fetchUserDatabases();
    }
  }, [isLoaded, isSignedIn, fetchUserDatabases]);

  useEffect(() => {
    if (isLoaded && isSignedIn && selectedPlatform) {
      fetchProperties();
    }
  }, [isLoaded, isSignedIn, selectedPlatform]);

  // Add this useEffect to refresh clients list when organization changes
  useEffect(() => {
    if (organization && isSignedIn) {
      fetchClients();
    }
  }, [organization?.id, isSignedIn]);
  

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  
  // Update your handleConnect function
  const handleConnect = async () => {
    if (!isSignedIn) {
      alert('Please sign in first.');
      return;
    }
  
    if (!selectedPlatform) {
      alert('Please select a platform before connecting.');
      return;
    }
  
    // Handle special cases first
    if (selectedPlatform.id === 'woocommerce') {
      setStep(1);
      return;
    }
  
    if (selectedPlatform.id === 'webcontent') {
      setStep(2);
      return;
    }
  
    try {
      // Show loading state
      setLoading(true);
  
      const response = await callApi('post', '/api/connect', { 
        platform_id: selectedPlatform.id 
      });
  
      if (response.auth_url) {
        const popup = createAuthPopup(response.auth_url, selectedPlatform.name);
        
        if (!popup) {
          setLoading(false);
          alert('Popup blocked. Please allow popups for this site and try again.');
          return;
        }
  
        let popupClosed = false;
        const popupCheck = setInterval(() => {
          if (popup.closed) {
            clearInterval(popupCheck);
            popupClosed = true;
            setLoading(false);
            
            // Only fetch properties if we haven't already
            fetchProperties().catch(error => {
              console.error('Error fetching properties after auth:', error);
              if (error.response?.status !== 401) {  // Don't show error if just unauthorized
                alert('Unable to fetch properties. Please try reconnecting.');
              }
            });
          }
        }, 1000);
  
        // Clear interval after reasonable time but don't show timeout message
        setTimeout(() => {
          if (!popupClosed) {
            clearInterval(popupCheck);
            setLoading(false);
            // Don't show timeout message, just clean up
            if (!popup.closed) {
              popup.close();
            }
          }
        }, 300000); // 5 minutes
      }
    } catch (error) {
      console.error('Error connecting to platform:', error);
      setLoading(false);
      
      let errorMessage = 'Unable to connect to the platform.';
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }
      if (error.response?.status === 401) {
        errorMessage = 'Please try reconnecting your account.';
      }
      
      alert(errorMessage);
    }
  };

  const [wooCredentials, setWooCredentials] = useState({
    consumer_key: '',
    consumer_secret: '',
    shop_name: '',
    start_date: ''
  });



  
  // Update the OAuth completion handler
  const handleOAuthComplete = useCallback((event) => {
    if (event.data.type === 'OAUTH_COMPLETE') {
      fetchProperties().catch(error => {
        console.error('Error fetching properties:', error);
        // Only show error if not an auth error
        if (error.response?.status !== 401) {
          setErrorMessage('Unable to fetch properties. Please try again.');
        }
      });
    }
  }, [fetchProperties]);
  
  // Then modify your useEffect to handle messages
  useEffect(() => {
    window.addEventListener('message', handleOAuthComplete);
    return () => {
      window.removeEventListener('message', handleOAuthComplete);
    };
  }, [handleOAuthComplete]);


  const handleAddToQueue = () => {
    if (!selectedPlatform) {
      alert('Please select a platform before adding to queue.');
      return;
    }
  
    if (selectedPlatform.id === 'woocommerce') {
      if (!wooCredentials.shop_name || !wooCredentials.consumer_key || !wooCredentials.consumer_secret) {
        alert('Please fill in all required WooCommerce fields');
        return;
      }
      if (!selectedClient) {
        alert('Please select a client first');
        return;
      }
  
      const newQueueItem = {
        platform: selectedPlatform,
        property: wooCredentials.shop_name,
        credentials: {
          consumer_key: wooCredentials.consumer_key,
          consumer_secret: wooCredentials.consumer_secret,
          start_date: wooCredentials.start_date
        },
        database: selectedClient
      };
  
      setDataSourceQueue(prev => [...prev, newQueueItem]);
      setWooCredentials({
        consumer_key: '',
        consumer_secret: '',
        shop_name: '',
        start_date: ''
      });
  
    } else if (selectedPlatform.id === 'webcontent') {
      if (!webContentUrl.trim()) {
        alert('Please enter a valid URL');
        return;
      }
      if (!selectedClient) {
        alert('Please select a client first');
        return;
      }
  
      const newQueueItem = {
        platform: selectedPlatform,
        property: webContentUrl,
        dateRange: 'N/A',
        database: selectedClient
      };
  
      setDataSourceQueue(prev => [...prev, newQueueItem]);
      setWebContentUrl('');
    } else {
      if (!selectedProperty) {
        alert('Please select a property before adding to queue.');
        return;
      }
  
      if (selectedProperty === 'all') {
        // Add all properties to queue
        properties.forEach(property => {
          const newQueueItem = {
            platform: selectedPlatform,
            property: property.id,
            dateRange: dateRange,
            database: null
          };
          setDataSourceQueue(prev => [...prev, newQueueItem]);
        });
      } else {
        // Add single property to queue
        const newQueueItem = {
          platform: selectedPlatform,
          property: selectedProperty,
          dateRange: dateRange,
          database: null
        };
        setDataSourceQueue(prev => [...prev, newQueueItem]);
      }
      
      setSelectedProperty('');
      setDateRange(30);
    }
    setStep(2);
  };

  const handleDatabaseSelect = (index, databaseName) => {
    setDataSourceQueue(prev => {
      const newQueue = [...prev];
      newQueue[index].database = databaseName;
      return newQueue;
    });
  };

  const handleStoreData = async (index) => {
    const source = dataSourceQueue[index];
    if (!source.database) {
      alert('Please select a database for this task.');
      return;
    }
  
    setStorageStatus(prev => ({ 
      ...prev, 
      [`${source.platform.id}-${source.property}`]: 'In Progress' 
    }));
  
    try {
      let response;

      if (source.platform.id === 'woocommerce') {
        response = await callApi('post', '/api/store_woocommerce_data', {
          shop_url: source.property,
          consumer_key: source.credentials.consumer_key,
          consumer_secret: source.credentials.consumer_secret,
          start_date: source.credentials.start_date,
          database: source.database,
        });
        
        setStorageStatus(prev => ({
          ...prev,
          [`${source.platform.id}-${source.property}`]: 'Success'
        }));
      
      // Handle web content storage
      } else if (source.platform.id === 'webcontent') {
        response = await callApi('post', '/api/store_web_content', {
          url: source.property,
          database: source.database,
        });
        
        if (response.urls_processed > 0) {
          setStorageStatus(prev => ({ 
            ...prev, 
            [`${source.platform.id}-${source.property}`]: 
              `Success (${response.urls_processed}/${response.total_urls} pages)` 
          }));
        } else {
          throw new Error('No pages were successfully processed');
        }
      } 
      // Handle GA4, GSC, and Facebook Ads storage
      else {
        response = await callApi('post', '/api/store_data', {
          platform_id: source.platform.id,
          property_id: source.property,
          date_range: source.dateRange,
          database: source.database,
        });
        
        setStorageStatus(prev => ({ 
          ...prev, 
          [`${source.platform.id}-${source.property}`]: 'Success' 
        }));
      }
    } catch (error) {
      console.error(`Error storing data for ${source.platform.name}:`, error);
      let errorMessage = 'An error occurred while storing data.';
      
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = 'Authentication has expired. Please reconnect your account.';
          handleReconnect(source.platform.id);
        } else if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
      }
      
      setStorageStatus(prev => ({ 
        ...prev, 
        [`${source.platform.id}-${source.property}`]: `Failed: ${errorMessage}` 
      }));
      alert(errorMessage);
    }
  };
  
  
  const handleReconnect = async (platformId) => {
    try {
      const response = await callApi('post', '/api/connect', { platform_id: platformId });
      if (response.auth_url) {
        window.open(response.auth_url, 'Google Sign-In', 'width=500,height=600');
      }
    } catch (error) {
      console.error('Error reconnecting:', error);
      alert('Failed to initiate reconnection. Please try again.');
    }
  };

  const WebContentSection = () => (
    <>
      <Typography variant="subtitle2" fontWeight="600" gutterBottom>
        Select Client:
      </Typography>
      <StyledSelect
        value={selectedClient}
        onChange={(e) => setSelectedClient(e.target.value)}
        fullWidth
        displayEmpty
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="" disabled>Select Client</MenuItem>
        {Array.isArray(clients) && clients.map((clientName) => (
          <MenuItem key={clientName} value={clientName}>
            {clientName}
          </MenuItem>
        ))}
      </StyledSelect>
    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Enter Website URL:
    </Typography>
    <TextField
      value={webContentUrl}
      onChange={(e) => setWebContentUrl(e.target.value)}
      fullWidth
      placeholder="https://example.com"
      error={!!webContentStatus.error}
      helperText={webContentStatus.error}
      disabled={webContentStatus.loading}
      sx={{ marginBottom: 2 }}
    />

    {webContentStatus.loading && (
      <Box sx={{ width: '100%', mb: 2 }}>
        <LinearProgress />
        <Typography variant="caption" align="center" display="block" sx={{ mt: 1 }}>
          Processing website content...
        </Typography>
      </Box>
    )}

    <StyledButton
      variant="contained"
      onClick={handleAddToQueue}
      fullWidth
      disabled={webContentStatus.loading || !webContentUrl.trim() || !selectedClient}
      sx={{ marginTop: 2, backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
    >
      Add to Queue
    </StyledButton>
  </>
);



const WooCommerceSection = () => (
  <>
    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Select Client:
    </Typography>
    <StyledSelect
      value={selectedClient}
      onChange={(e) => setSelectedClient(e.target.value)}
      fullWidth
      displayEmpty
      sx={{ marginBottom: 2 }}
    >
      <MenuItem value="" disabled>Select Client</MenuItem>
      {Array.isArray(clients) && clients.map((clientName) => (
        <MenuItem key={clientName} value={clientName}>
          {clientName}
        </MenuItem>
      ))}
    </StyledSelect>

    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Shop URL:
    </Typography>
    <TextField
      value={wooCredentials.shop_name}
      onChange={(e) => setWooCredentials(prev => ({...prev, shop_name: e.target.value}))}
      fullWidth
      placeholder="https://your-store.com"
      sx={{ marginBottom: 2 }}
    />

    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Consumer Key:
    </Typography>
    <TextField
      value={wooCredentials.consumer_key}
      onChange={(e) => setWooCredentials(prev => ({...prev, consumer_key: e.target.value}))}
      fullWidth
      type="text"
      sx={{ marginBottom: 2 }}
    />

    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Consumer Secret:
    </Typography>
    <TextField
      value={wooCredentials.consumer_secret}
      onChange={(e) => setWooCredentials(prev => ({...prev, consumer_secret: e.target.value}))}
      fullWidth
      type="password"
      sx={{ marginBottom: 2 }}
    />

    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
      Start Date:
    </Typography>
    <TextField
      type="date"
      value={wooCredentials.start_date}
      onChange={(e) => setWooCredentials(prev => ({...prev, start_date: e.target.value}))}
      fullWidth
      sx={{ marginBottom: 2 }}
      InputLabelProps={{
        shrink: true,
      }}
    />

    <StyledButton
      variant="contained"
      onClick={handleAddToQueue}
      fullWidth
      sx={{ marginTop: 2, backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
    >
      Add to Queue
    </StyledButton>

    <Link 
      href="http://woocommerce.github.io/woocommerce-rest-api-docs/#rest-api-keys"
      target="_blank"
      rel="noopener"
      sx={{ textDecoration: 'none', display: 'block', textAlign: 'center', marginTop: 2 }}
    >
      <Typography variant="body2" color="primary">
        How to get WooCommerce API keys
      </Typography>
    </Link>
  </>
);


const renderPropertySelection = () => {
  if (properties.length > 0) {
    // Sort properties alphabetically
    const sortedProperties = [...properties].sort((a, b) => {
      const nameA = (a.name || a.displayName || '').toLowerCase();
      const nameB = (b.name || b.displayName || '').toLowerCase();
      return nameA.localeCompare(nameB);
    });

    // Filter properties based on search
    const filteredProperties = sortedProperties.filter(property => {
      const searchLower = propertySearchTerm.toLowerCase();
      const propertyName = (property.name || property.displayName || '').toLowerCase();
      const propertyId = (property.id || '').toLowerCase();
      return propertyName.includes(searchLower) || propertyId.includes(searchLower);
    });

    return (
      <>
        <Typography variant="subtitle2" fontWeight="600" gutterBottom>
          Select Property:
        </Typography>
        <StyledSelect
          value={selectedProperty}
          onChange={handlePropertySelect}
          fullWidth
          displayEmpty
          sx={{ marginBottom: 2 }}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 450 },
              sx: { 
                "& .MuiMenuItem-root": { 
                  padding: "8px 16px" 
                }
              }
            }
          }}
        >
          <MenuItem value="" disabled>Select Property</MenuItem>
          
          {/* Search TextField inside Select with improved visibility */}
          <MenuItem 
            sx={{ 
              p: 0, 
              m: 1, 
              position: 'sticky', 
              top: 0, 
              bgcolor: 'background.paper', 
              zIndex: 1,
              "&.MuiMenuItem-root": {
                "&:hover": {
                  bgcolor: 'background.paper'  // Prevent hover effect
                }
              }
            }}
            disableRipple  // Disable ripple effect
          >
            <TextField
              size="small"
              placeholder="Search properties..."
              value={propertySearchTerm}
              onChange={(e) => {
                e.stopPropagation();
                setPropertySearchTerm(e.target.value);
              }}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}  // Prevent select from handling keydown
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                sx: {
                  color: 'text.primary',  // Make text color visible
                  '& input': {
                    color: 'text.primary',  // Ensure input text is visible
                    '&::placeholder': {
                      color: 'text.secondary',  // Make placeholder visible but distinct
                      opacity: 0.7
                    }
                  },
                  bgcolor: '#F1F5F9',
                  '&:hover': {
                    bgcolor: '#F1F5F9'  // Maintain background on hover
                  }
                }
              }}
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  bgcolor: '#F1F5F9',
                  '&:hover': {
                    bgcolor: '#F1F5F9'  // Maintain background on hover
                  }
                }
              }}
            />
          </MenuItem>

          {filteredProperties.map((property) => (
            <MenuItem 
              key={property.id} 
              value={property.id}
            >
              {property.name || property.displayName}
            </MenuItem>
          ))}
        </StyledSelect>
      </>
    );
  }
  return null;
};

// Update the renderDataStorageStatus function
const renderDataStorageStatus = () => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>Data Source</StyledTableCell>
          <StyledTableCell>Property</StyledTableCell>
          <StyledTableCell>Date Range</StyledTableCell>
          <StyledTableCell>Client</StyledTableCell>
          <StyledTableCell>Status</StyledTableCell>
          <StyledTableCell>Action</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataSourceQueue.map((source, index) => (
          <TableRow key={index}>
            <StyledTableCell>{source.platform.name}</StyledTableCell>
            <StyledTableCell>{source.property}</StyledTableCell>
            <StyledTableCell>{source.platform.id === 'webcontent' ? 'N/A' : `${source.dateRange} days`}</StyledTableCell>
            <StyledTableCell>
              {source.database || (
                <Select
                  value={source.database || ''}
                  onChange={(e) => handleDatabaseSelect(index, e.target.value)}
                  displayEmpty
                  fullWidth
                  size="small"
                >
                  <MenuItem value="" disabled>Select Client</MenuItem>
                  {Array.isArray(clients) && clients.map((clientName) => (
                    <MenuItem key={clientName} value={clientName}>
                      {clientName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {storageStatus[`${source.platform.id}-${source.property}`] || 'Queued'}
              {storageStatus[`${source.platform.id}-${source.property}`] === 'In Progress' && 
                <CircularProgress size={16} style={{ marginLeft: 8 }} />
              }
            </StyledTableCell>
            <StyledTableCell>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleStoreData(index)}
                disabled={
                  !source.database || 
                  storageStatus[`${source.platform.id}-${source.property}`] === 'In Progress' || 
                  storageStatus[`${source.platform.id}-${source.property}`]?.startsWith('Success')
                }
              >
                Store Data
              </Button>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);


  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  
  if (!isSignedIn) {
    return <div>Please sign in to access this page.</div>;
  }

  const handlePropertySelect = (event) => {
    setSelectedProperty(event.target.value);
  };

  const handleNextStep = () => {
    if (selectedProperty) {
      setStep(2);
    }
  };

  const fetchClients = async () => {
    if (!organization) return;
    
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/clients`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (Array.isArray(response.data)) {
        setClients(response.data);
        setError(null);
        
        // If currently selected client is not in the new list, reset it
        if (selectedClient && !response.data.includes(selectedClient)) {
          setSelectedClient('');
        }
      } else {
        console.error('Invalid response format:', response.data);
        setError('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError(error.response?.data?.error || 'Failed to fetch clients');
    } finally {
      setLoading(false);
    }
  };
  

  const handleCreateNewClient = async () => {
    if (!organization) {
      setError("Please select an organization first");
      return;
    }
  
    if (!newClientName.trim()) {
      setError("Please enter a client name");
      return;
    }
    
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.post(
        `${BACKEND_URL}/api/create_database`,
        {
          name: newClientName.trim(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.success) {
        // Update the clients list immediately by adding the new client
        setClients(prevClients => [...prevClients, newClientName.trim()]);
        
        // Clear the input and error
        setNewClientName('');
        setError(null);
        
        // Fetch the updated list to ensure synchronization
        await fetchClients();
        
        // Show success message
        alert('New client database created successfully!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      setError(error.response?.data?.error || 'Failed to create client');
    } finally {
      setLoading(false);
    }
  };
  


  const handleProcessWebContent = async () => {
    if (!webContentUrl.trim()) {
      alert('Please enter a valid URL');
      return;
    }
  
    setWebContentStatus({
      loading: true,
      progress: 0,
      error: null,
      success: false
    });
  
    try {
      const token = await getToken();
      await axios.post(
        `${BACKEND_URL}/api/store_web_content`,
        {
          url: webContentUrl,
          database: selectedClient
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );
  
      setWebContentStatus({
        loading: false,
        progress: 100,
        error: null,
        success: true
      });
  
      // Add to queue after successful processing
      handleAddToQueue();
      
    } catch (error) {
      console.error('Error processing web content:', error);
      setWebContentStatus({
        loading: false,
        progress: 0,
        error: error.response?.data?.error || 'Failed to process web content',
        success: false
      });
    }
  };
  

  const renderDataPreview = () => {
    // ... (keep the existing renderDataPreview function)
  };

  const filteredPlatforms = platforms.filter(platform =>
    platform.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!showOnlyConnected || platform.connected)
  );


  const handleSourceSelect = (source) => {
    setSelectedSources(prev => {
      if (prev.includes(source)) {
        return prev.filter(s => s !== source);
      } else {
        return [...prev, source];
      }
    });
  };

  const handleStoreMultipleData = async () => {
    if (selectedSources.length === 0 || !selectedDatabase) {
      alert('Please select at least one data source and a database.');
      return;
    }

    setLoading(true);
    const newStatus = {};

    for (const source of selectedSources) {
      newStatus[source.id] = 'In Progress';
      setStorageStatus(prev => ({ ...prev, ...newStatus }));

      try {
        const response = await callApi.post('/api/store_data', {
          platform_id: source.id,
          property_id: selectedProperty,
          date_range: dateRange,
          database: selectedDatabase,
        }, {
        });
        
        newStatus[source.id] = 'Success';
      } catch (error) {
        console.error(`Error storing data for ${source.name}:`, error);
        newStatus[source.id] = 'Failed';
      }

      setStorageStatus(prev => ({ ...prev, ...newStatus }));
    }

    setLoading(false);
    alert('Data storage process completed. Check the status table for details.');
  };



  return (
    <AppContainer>
      <Sidebar>
        {/* Organization Section */}
        <Box sx={{ padding: '6px', borderBottom: '1px solid #E2E8F0' }}>
          <Typography variant="caption" sx={{ fontWeight: 700, display: 'block', mb: 1 }}>
            Organization
          </Typography>
          <OrganizationSwitcher 
            appearance={{
              elements: {
                rootBox: {
                  width: '100%',
                },
                organizationSwitcherTrigger: {
                  padding: '8px',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  backgroundColor: '#F8FAFC'
                }
              }
            }}
          />
        </Box>
        
        {error && (
          <Typography color="error" sx={{ padding: 2 }}>
            {error}
          </Typography>
        )}
        <TextField
          placeholder="Search platform"
          variant="outlined"
          size="small"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{ marginBottom: 2, marginTop: 4, backgroundColor: '#F1F5F9', borderRadius: 2, '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
        />
        {/* <Box display="flex" alignItems="center" mb={2}>
          <Checkbox
            checked={showOnlyConnected}
            onChange={(e) => setShowOnlyConnected(e.target.checked)}
            size="small"
          />
          <Typography variant="body2" fontSize="12px">Show only connected</Typography>
        </Box> */}


        <List>
          {filteredPlatforms.map((platform) => (
            <PlatformListItem
              button
              key={platform.id}
              onClick={() => setSelectedPlatform(platform)}
              selected={selectedPlatform?.id === platform.id}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <img src={platform.icon} alt={platform.name} style={{ width: 60, height: 'auto' }} />
              </ListItemIcon>
              <ListItemText primary={platform.name} primaryTypographyProps={{ fontSize: '12px' }} />
            </PlatformListItem>
          ))}
        </List>
        <StyledButton
          startIcon={<HomeIcon />}
          variant="contained"
          fullWidth
          sx={{ marginTop: 'auto', backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
          onClick={handleHomeClick}
        >
          Back to Home
        </StyledButton>
      </Sidebar>
      <MainContent>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Connect Your Marketing Platforms
        </Typography>
        <Typography variant="body2" gutterBottom color="text.secondary" fontSize="14px">
        </Typography>
        <Box display="flex" mt={2} mb={3}>
          <StyledButton
            variant={step === 1 ? "contained" : "outlined"}
            color="primary"
            onClick={() => setStep(1)}
            sx={{ backgroundColor: step === 1 ? '#6366F1' : 'transparent', color: step === 1 ? '#fff' : '#6366F1', '&:hover': { backgroundColor: step === 1 ? '#4F46E5' : 'transparent' } }}
          >
            1. Add data
          </StyledButton>
          <ArrowForwardIcon sx={{ margin: '8px 8px', color: '#CBD5E1' }} />
          <StyledButton
            variant={step === 2 ? "contained" : "outlined"}
            color="primary"
            onClick={() => setStep(2)}
            sx={{ backgroundColor: step === 2 ? '#6366F1' : 'transparent', color: step === 2 ? '#fff' : '#6366F1', '&:hover': { backgroundColor: step === 2 ? '#4F46E5' : 'transparent' } }}
          >
            2. Store Client Data
          </StyledButton>
        </Box>

        <Box sx={{ backgroundColor: '#F8FAFC', padding: '24px', borderRadius: '16px', flexGrow: 1 }}>
          <ContentCard>
            {step === 1 && (
      <>
        <Typography variant="h6" fontWeight="bold" align="center" gutterBottom>
          Connect your {selectedPlatform ? selectedPlatform.name : 'data source'} account
        </Typography>
        
        <Box display="flex" flexDirection="column" alignItems="center">
          {selectedPlatform && selectedPlatform.id === 'woocommerce' ? (
            <WooCommerceSection />
          ) : selectedPlatform && selectedPlatform.id === 'webcontent' ? (
            <WebContentSection />
          ) : selectedPlatform && selectedPlatform.id === 'facebook_ads' ? (
            <>
              <StyledButton
                variant="contained"
                onClick={handleConnect}
                sx={{ marginBottom: 3, backgroundColor: '#1877F2', '&:hover': { backgroundColor: '#166FE5' } }}
                startIcon={<img src={FacebookIcon} alt="Facebook" width="20" height="20" />}
              >
                Sign in with Facebook
              </StyledButton>

              {/* Show property selection and date range after connection */}
              {renderPropertySelection()}

              {selectedProperty && (
                <>
                  <Typography variant="subtitle2" fontWeight="600" gutterBottom>
                    Select Date Range:
                  </Typography>
                  <StyledSelect
                    value={dateRange}
                    onChange={(e) => setDateRange(e.target.value)}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                  >
                    <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={90}>Last 90 days</MenuItem>
                  </StyledSelect>

                  <StyledButton
                    variant="contained"
                    onClick={handleAddToQueue}
                    fullWidth
                    sx={{ backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
                  >
                    Add to Queue
                  </StyledButton>
                </>
              )}
            </>
          ) : (
            /* Google Platforms Section */
            <>
              <StyledButton
                variant="contained"
                onClick={handleConnect}
                sx={{ marginBottom: 3, backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
                startIcon={<img src={GoogleIcon} alt="Google" width="20" height="20" />}
              >
                Sign in with Google
              </StyledButton>

              {/* Show property selection and date range after connection */}
              {renderPropertySelection()}

              {selectedProperty && (
                <>
                  <Typography variant="subtitle2" fontWeight="600" gutterBottom>
                    Select Date Range:
                  </Typography>
                  <StyledSelect
                    value={dateRange}
                    onChange={(e) => setDateRange(e.target.value)}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                  >
                    <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={90}>Last 90 days</MenuItem>
                  </StyledSelect>

                  <StyledButton
                    variant="contained"
                    onClick={handleAddToQueue}
                    fullWidth
                    sx={{ backgroundColor: '#6366F1', '&:hover': { backgroundColor: '#4F46E5' } }}
                  >
                    Add to Queue
                  </StyledButton>
                </>
              )}
            </>
          )}
        </Box>
      </>
    )}

{step === 2 && (
  <>
    <Typography variant="h6" fontWeight="bold" gutterBottom>Queued Data Sources:</Typography>
    {renderDataStorageStatus()}
    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        placeholder="New Client Name"
        value={newClientName}
        onChange={(e) => setNewClientName(e.target.value)}
        size="small"
        style={{ flexGrow: 1, marginRight: 16 }}
      />
      <Button
        variant="contained"
        onClick={handleCreateNewClient}
        disabled={!newClientName.trim() || loading}
      >
        {loading ? 'Creating...' : 'Create'}
      </Button>
    </Box>
    <Box mt={2}>
      <Typography variant="subtitle2" fontWeight="600" gutterBottom>
        Select Client:
      </Typography>
      <StyledSelect
        value={selectedClient}
        onChange={handleClientChange}
        fullWidth
        displayEmpty
        disabled={loading}
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="" disabled>
          {loading ? 'Loading clients...' : 'Select Client'}
        </MenuItem>
        {Array.isArray(clients) && clients.map((clientName) => (
          <MenuItem key={clientName} value={clientName}>
            {clientName}
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
    <Box mt={2}>
      <Button
        variant="outlined"
        onClick={() => setStep(1)}
        fullWidth
      >
        Add More Data Sources
      </Button>
    </Box>
  </>
)}
          </ContentCard>
        </Box>
      </MainContent>
    </AppContainer>
  );
};

export default DataSourceConnector;