import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser, useAuth, useOrganization as useClerkOrganization } from '@clerk/clerk-react';
import axios from 'axios';

const OrganizationContext = createContext();
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const OrganizationProvider = ({ children }) => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const { organization, isLoaded } = useClerkOrganization();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (organization && user) {
      syncOrganizationWithBackend();
    }
  }, [organization?.id, user?.id]);

  const syncOrganizationWithBackend = async () => {
    try {
      const token = await getToken();
      await axios.post(`${BACKEND_URL}/api/sync_organization`, {
        id: organization.id,
        name: organization.name,
        role: organization.membership.role
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    } catch (err) {
      console.error('Error syncing organization:', err);
      setError('Failed to sync organization data');
    }
  };

  return (
    <OrganizationContext.Provider value={{
      organization,
      isLoaded,
      error
    }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error('useOrganization must be used within an OrganizationProvider');
  }
  return context;
};
