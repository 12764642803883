import React, { useEffect, useState } from 'react';
import { useClerk, useOrganization } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

export default function SubscriptionSuccess() {
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState('');
  const { organization } = useOrganization();
  const { session } = useClerk();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const verifySubscription = async () => {
      if (!sessionId || !organization) {
        setStatus('error');
        setError('Missing required information');
        return;
      }

      try {
        const token = await session.getToken();
        
        // Verify the checkout session
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/verify-checkout-session`,
          {
            params: { session_id: sessionId },
            headers: {
              'Authorization': `Bearer ${token}`,
              'X-Organization-ID': organization.id
            }
          }
        );

        console.log('Verification response:', response.data);

        if (response.data.success) {
          setStatus('success');
          // Redirect after 5 seconds
          setTimeout(() => {
            navigate('/manage-subscription');
          }, 5000);
        } else {
          setStatus('error');
          setError(response.data.error || 'Failed to verify subscription');
        }
      } catch (error) {
        console.error('Error verifying subscription:', error);
        setStatus('error');
        setError(error.response?.data?.error || 'An unexpected error occurred');
      }
    };

    verifySubscription();
  }, [sessionId, organization, session, navigate]);

  return (
    <div className="subscription-success-container">
      {status === 'loading' && (
        <div className="loading-state">
          <h2>Confirming your subscription...</h2>
          <div className="spinner"></div>
        </div>
      )}
      
      {status === 'success' && (
        <div className="success-state">
          <h2>Thank you for your subscription!</h2>
          <p>Your subscription has been confirmed and your account has been upgraded.</p>
          <p>You will be redirected to the subscription management page in 5 seconds...</p>
          <button onClick={() => navigate('/manage-subscription')}>
            Return to Subscription Page
          </button>
        </div>
      )}
      
      {status === 'error' && (
        <div className="error-state">
          <h2>Something went wrong</h2>
          <p>{error || "We couldn't confirm your subscription. Please contact support if this persists."}</p>
          <button onClick={() => navigate('/manage-subscription')}>
            Return to Subscription Page
          </button>
        </div>
      )}
    </div>
  );
}